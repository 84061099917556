<template lang="pug">
include ../pug/svg
section#lang
	div
		template(v-for="(group, groupKey) in termMap")
			h4 {{group.name}}
			div.field.c2(v-for="(termVars, termKey) in group.vars" :class="{active:termVars.active}")
				label {{label(termVars.default)}}
				span(:contenteditable="isEditable(termVars.active)" spellcheck="false" @keydown="keyCheck" @keyup="updateTerm(groupKey,termKey,'singular',$event)" data-singular="Singular term") {{termVars.custom.singular}}
				
				span(v-if="termVars.default.plural") /
				
				span(v-if="termVars.default.plural" :contenteditable="isEditable(termVars.active)" spellcheck="false" @keydown="keyCheck" @keyup="updateTerm(groupKey,termKey,'plural',$event)" data-plural="Plural term") {{termVars.custom.plural}}

				input(type="checkbox" :value="termVars.active" :checked="termVars.active===true" value="true" @change="updateTerm(groupKey,termKey,'active',$event)")
</template>

<script>	
export default {
	name: 'ManageThemeTerminology',
	props: ['cancelled'],
	emits: ['storeUpdated'],
	data() {		
		return {
			preventUpdateTerm: false,
			caretOffset: null,
		};
	},
	mounted() {
		this.$store.dispatch('gui/setHeader', {
			title: this.terminology('interface', 'manage', 'singular') + ' ' + this.terminology('theme','theme','singular'),
			backRoute: {
				text: 'Dashboard',
				name: 'Dashboard',
			},
		});
	},
	watch: {
		themeName: {
			handler(themeName) {
				this.$store.dispatch('gui/setHeader', {
					title: themeName,
					backRoute: {
						text: 'Dashboard',
						name: 'Dashboard',
					},
				});		
			},
			deep: false,
		},
	},
	computed: {
		termMap() {
			return this.$store.state.gui.termMap;
		},
		themeName() {
			return 'Manage ' + this.terminology('theme','theme','singular');			
		},
	},
	methods: {
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		label(termVars) {
			return (termVars.plural) ? termVars.singular+' / '+termVars.plural : termVars.singular;
		},
		isEditable(active) {
			return (active) ? 'true' : 'false';
		},
		keyCheck(event) {
			if (event.key === 'Enter') {
				// prevent newline
				event.preventDefault();
				this.preventUpdateTerm = true;
			}
			
			let caretOffset = window.getSelection().anchorOffset;			
			this.caretOffset = (event.key === 'Backspace') ? caretOffset - 1 : caretOffset + 1;
		},
		updateTerm(groupKey, termKey, varKey, event) {
console.log('updateTerm:', groupKey, termKey, varKey, event);
			if (this.preventUpdateTerm) {
				this.preventUpdateTerm = false;
				return;
			}
				
			let value;
			
			if (varKey === 'active') {
				// convert string to bool
				value = (event.target.value === 'true') ? true : false;
				value = !value;
				
			} else {
				value = event.target.innerText;
			}
			
			this.$store.dispatch('gui/setTermMap', {
				groupKey,
				termKey,
				varKey,
				value,
			});
			
			if (varKey === 'active') {
				console.log('terminology:', this.terminology(groupKey, termKey, termKey));
				//if (value) {
					
				//}
				/*this.$emit('storeUpdated', {
					screen: 'terminology',
					valid: true,
				});*/
				
				return;
			}
			
			this.$nextTick(() => {
				// await store update
				if (event.key === 'Shift' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') {
					// navigation keys
					return;
				}
				if (this.caretOffset < 0) {
					this.caretOffset = 0;	
				} else if (this.caretOffset > value.length) {
					this.caretOffset = value.length;
				}
				
				let node = event.target.childNodes.length ? event.target.childNodes[0] : event.target;
				let range = document.createRange();
				range.setEnd(node, this.caretOffset);
				range.collapse(false);
				
				let sel = window.getSelection();
				sel.removeAllRanges();
				sel.addRange(range);
				
				this.$emit('storeUpdated', {
					screen: 'terminology',
					valid: true,
				});
			});
		},
		updateTerms() {},
	},
}
</script>

<style lang="scss">
#lang {
	>div {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	h4 {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 0 10px;
		margin-top: 20px;
		min-height: 30px;
		font-size: 1.6rem;
		font-weight: 500;
	}
	.field {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		max-width: none;
		label {
			width: 100%;
		}
		[contenteditable] {
			&:nth-of-type(1) {
				&:not(:empty) {
					color: var(--pan-lnk);
				}
				&:empty {
					&:before {
						content: attr(data-singular);
						color: var(--pan-lnk-dis);
					}
					+span { // forward slash seperator
						color: var(--pan-lnk-dis);
					}
				}
			}
			&:nth-of-type(3) {
				&:empty:before {
					content: attr(data-plural);
					color: var(--pan-lnk-dis);
				}
				&:not(:empty) {
					color: var(--pan-lnk);
				}
			}
		}
		span:nth-of-type(2) {
			padding: 0 5px 0 6px;
		}
		span,
		span:before {
			transition: color .3s ease-in-out;
		}
		&:not(.active) {
			span {
				color: var(--pan-lnk-dis) !important;
				cursor: default;
			}
		}
	}}
</style>
